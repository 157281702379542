import React from 'react';
import { Link } from 'react-router-dom';

const AssignmentList = () => {
    // Mock assignment data
    const assignments = [
        { id: 1, title: 'MicroProcessor and Micro Controller' },
        { id: 2, title: 'Electronic Device' },
        { id: 3, title: 'Circuit Theory' },
    ];

    return (
        <div className="assignment-list-container">
            
            <h2>Assignments</h2>
            <ul className="assignment-list">
                {assignments.map((assignment) => (
                    <li key={assignment.id} className="assignment-item">
                        <Link to={`/assignment/${assignment.id}`}>
                            {assignment.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AssignmentList;
