import React from 'react';
import BreadcrumbShape1 from '../assets/img/breadcrumb/shape-1.png';
import BreadcrumbBG from '../assets/img/bg/2/1.jpg';

const Contactus = () => {
    return (
        <div>
             <section class="breadcrumb-area bg-default" data-background={BreadcrumbBG} style={{ backgroundImage: `url(${BreadcrumbBG})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <img src={BreadcrumbShape1} alt="" class="breadcrumb-shape"/>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumb-content">
                                <h2 class="breadcrumb-title">Sign up</h2>
                                <div class="breadcrumb-list">
                                    <a href="index.html">Home</a>
                                    <span>Sign up</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div class="account-area pt-120 pb-120">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-7 col-lg-8 col-md-10">
                            <div class="account-wrap">
                                <div class="account-top sign-up">
                                    <div class="account-top-current">
                                        <span>Sign Up</span>
                                    </div>
                                    <div class="account-top-link">
                                        <a href="/Signin">Sign In</a>
                                    </div>
                                </div>
                                <div class="account-main">
                                    <h3 class="account-title">Sign in to Your Account 👋</h3>
                                    <form action="#" class="account-form">
                                        <div class="account-form-item mb-20">
                                            <div class="account-form-label">
                                                <label>First Name</label>
                                            </div>
                                            <div class="account-form-input">
                                                <input type="text" placeholder="First Name"/>
                                            </div>
                                        </div>
                                        <div class="account-form-item mb-20">
                                            <div class="account-form-label">
                                                <label>Last Name</label>
                                            </div>
                                            <div class="account-form-input">
                                                <input type="text" placeholder="Last Name"/>
                                            </div>
                                        </div>
                                        <div class="account-form-item mb-20">
                                            <div class="account-form-label">
                                                <label>Your Email</label>
                                            </div>
                                            <div class="account-form-input">
                                                <input type="email" placeholder="Enter Your Email"/>
                                            </div>
                                        </div>
                                        <div class="account-form-item mb-15">
                                            <div class="account-form-label">
                                                <label>Your Password</label>
                                                <a href="#">Forgot Password ?</a>
                                            </div>
                                            <div class="account-form-input account-form-input-pass">
                                                <input type="text" placeholder="*********"/>
                                                <span><i class="fa-thin fa-eye"></i></span>
                                            </div>
                                        </div>
                                        <div class="account-form-condition">
                                            <label class="condition_label">Remember Me
                                                <input type="checkbox"/>
                                                <span class="check_mark"></span>
                                            </label>
                                        </div>
                                        <div class="account-form-button">
                                            <button type="submit" class="account-btn">Sign Up</button>
                                        </div>
                                    </form>
                                    <div class="account-break">
                                        <span>OR</span>
                                    </div>
                                    <div class="account-bottom">
                                        <div class="account-option">
                                            <a href="#" class="account-option-account">
                                                <img src="assets/img/bg/google.png" alt=""/>
                                                <span>Google</span>
                                            </a>
                                            <a href="#" class="account-option-account">
                                                <img src="assets/img/bg/apple.png" alt=""/>
                                                <span>Apple</span>
                                            </a>
                                            <a href="#" class="account-option-account">
                                                <img src="assets/img/bg/facebook.png" alt=""/>
                                                <span>Facebook</span>
                                            </a>
                                        </div>
                                        <div class="account-bottom-text">
                                            <p>Already have an account ?  <a href="sign-in.html">Sign In for here</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contactus;