import React from 'react';
import BreadcrumbShape1 from '../assets/img/breadcrumb/shape-1.png';
import BreadcrumbBG from '../assets/img/breadcrumb/breadcrumb-bg.jpg';
import Event1 from '../assets/img/course/1/course1.jpg';
import Event2 from '../assets/img/course/1/course2.jpg';
import Event3 from '../assets/img/course/1/course3.jpg';
import Event4 from '../assets/img/course/1/course4.jpg';
import Event5 from '../assets/img/course/1/course5.jpg';
import Event6 from '../assets/img/course/1/course6.jpg';

const events = [
    {
        image: Event1,
        location: 'Main Auditorium',
        time: 'March 15-16, 2024',
        title: 'TechFest 2024: A two-day technical festival.',
        link: '#/EventDetails'
    },
    {
        image: Event2,
        location: 'Room 203, Engineering Block',
        time: 'April 5, 2024',
        title: 'Guest Lecture: Innovations in Robotics.',
        link: '#/EventDetails'
    },

    {
        image: Event4,
        location: 'Computer Lab 1',
        time: 'April 12, 2024',
        title: 'Annual Sports Day: Participate in various sports events.',
        link: '#/EventDetails'
    },
    {
        image: Event5,
        location: 'Seminar Hall A',
        time: 'April 25-26, 2024',
        title: 'Workshop on Machine Learning: Hands-on workshop.',
        link: '#/EventDetails'
    },
    {
        image: Event6,
        location: 'College Auditorium',
        time: 'May 3, 2024',
        title: 'Cultural Night: Evening of performances.',
        link: '#/EventDetails'
    }
];

const Teachers = () => {
    return (
        <div>
            <section className="breadcrumb-area bg-default" style={{ backgroundImage: `url(${BreadcrumbBG})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="breadcrumb-title">Upcoming Events</h2>
                                <div className="breadcrumb-list">
                                    <a href="index.html">Home</a>
                                    <span>Upcoming Events</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="innerPage_event-area pt-120 pb-90">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6">
                            <div className="section-area mb-50 section-area-top text-center">
                                <span className="section-subtitle">Conference on Education</span>
                                <h2 className="section-title mb-20">Upcoming Events</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {events.map((event, index) => (
                            <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                                <div className="event-item mb-30">
                                    <div className="event-img">
                                        <img src={event.image} alt="" />
                                    </div>
                                    <div className="event-content">
                                        <div className="event-content-meta">
                                            <span><i className="fa-thin fa-location-dot"></i>{event.location}</span>
                                            <span><i className="fa-thin fa-calendar"></i>{event.time}</span> {/* Changed to calendar icon */}
                                        </div>
                                        <h5 className="event-content-title">
                                            <a href={event.link}>{event.title}</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Teachers;
