import React from 'react';
import { FaStar, FaExclamationCircle } from 'react-icons/fa';

const OverallReport = () => {
    const exams = [
        { name: 'Internal Exam-1', totalMarks: 600, obtainedMarks: 575 },
        { name: 'Internal Exam-2', totalMarks: 600, obtainedMarks: 540 },
        { name: 'Internal Exam-3', totalMarks: 600, obtainedMarks: 560 },
        { name: 'Model Exam', totalMarks: 600, obtainedMarks: 480 },
        { name: 'Final Exam', totalMarks: 600, obtainedMarks: 450 },

    ];
    const getIcon = (percentage) => {
        if (percentage >= 90) return <FaStar style={{ color: '#4caf50' }} />;
        return <FaExclamationCircle style={{ color: '#f44336' }} />;
    };
    // Function to calculate the overall percentage
    const calculatePercentage = (obtained, total) => {
        return ((obtained / total) * 100).toFixed(2);
    };

    return (
        <div className="overall-report-container">
            <h1 className="report-title">Overall Exam Report</h1>
            <div className="report-summary">
                <h2 className="summary-heading">Summary of Exams</h2>
                <table className="report-table">
                    <thead>
                        <tr>
                            <th>Exam Name</th>
                            <th>Total Marks</th>
                            <th>Obtained Marks</th>
                            <th>Percentage</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                    {exams.map((exam, index) => {
                            const percentage = calculatePercentage(exam.obtainedMarks, exam.totalMarks);
                            return (
                                <tr key={index}>
                                    <td>{exam.name}</td>
                                    <td>{exam.totalMarks}</td>
                                    <td>{exam.obtainedMarks}</td>
                                <td>{calculatePercentage(exam.obtainedMarks, exam.totalMarks)}%</td>
                                <td>{getIcon(percentage)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OverallReport;
