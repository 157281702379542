import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const AssignmentDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    // Mock assignment data
    const assignments = [
        { id: 1, title: 'Understanding the 8085 Microprocessor Architecture', description: 'Draw the architecture of the 8085 microprocessor and explain each component. Discuss the role of the ALU, Control Unit, Registers, and Buses in the operation of the microprocessor. Explain how memory and I/O are interfaced with the 8085. Provide examples of how the 8085 microprocessor is used in real-world applications.', dueDate: '2024-09-20' },
        { id: 2, title: 'Characteristics of PN Junction Diode', description: 'Study the characteristics of a PN junction diode in forward and reverse bias. Draw the I-V curve and explain the behavior of the diode in both conditions.Explain the concept of depletion region and how the width of this region changes with the applied voltage.Discuss the applications of PN junction diodes in rectifiers and voltage regulators.', dueDate: '2024-09-25' },
        { id: 3, title: 'Ohm s Law and Basic Circuit Analysis', description: 'Explain the concept of Ohm’s Law and its application in simple circuits.Solve for current, voltage, and resistance in series and parallel circuits using Ohms Law.Design a simple resistive circuit and calculate the total resistance, voltage drop across each resistor, and current through the circuit.', dueDate: '2024-09-30' },
    ];

    // Find the assignment by ID
    const assignment = assignments.find((item) => item.id === parseInt(id));

    const handleClose = () => {
        navigate('/');
    };

    if (!assignment) {
        return <p>Assignment not found</p>;
    }

    return (
        <div className="assignment-details-container">
            <h2>{assignment.title}</h2>
            <p><strong>Description:</strong> {assignment.description}</p>
            <p><strong>Due Date:</strong> {assignment.dueDate}</p>
            <button onClick={handleClose} className="close-button">✖</button>

        </div>
 
    );
};

export default AssignmentDetails;
