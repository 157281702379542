import React from 'react';
import BreadcrumbShape1 from '../assets/img/breadcrumb/shape-1.png';
import BreadcrumbBG from '../assets/img/breadcrumb/breadcrumb-bg.jpg';
import Teacher2 from '../assets/img/teacher/3/teacher1.jpg'


const Teachers = () => {
    return (
        <div>
            <section class="breadcrumb-area bg-default" data-background={BreadcrumbBG}style={{ backgroundImage: `url(${BreadcrumbBG})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <img src={BreadcrumbShape1} alt="" class="breadcrumb-shape"/>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumb-content">
                                <h2 class="breadcrumb-title">Our Professor details</h2>
                                <div class="breadcrumb-list">
                                    <a href="index.html">Home</a>
                                    <span>Our Professor</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="teacher_details-area pt-120 pb-60">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-5">
                            <div class="teacher_details-img w_img mb-50">
                                <img src={Teacher2} alt=""/>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-7">
                            <div class="teacher_details-content mb-50">
                                <div class="teacher_details-admin">
                                    <h4 class="teacher_details-title">Marvin McKinney</h4>
                                    <span>Web Designer, Researcher</span>
                                </div>
                                <div class="teacher_details-rating">
                                    <ul>
                                        <li><i class="fa-solid fa-star"></i></li>
                                        <li><i class="fa-solid fa-star"></i></li>
                                        <li><i class="fa-solid fa-star"></i></li>
                                        <li><i class="fa-solid fa-star"></i></li>
                                        <li><i class="fa-solid fa-star"></i></li>
                                    </ul>
                                    <span>(03 Reviews)</span>
                                </div>
                                <h5 class="teacher_details-title2">About Me</h5>
                                <p class="mb-20">Lorem ipsum dolor sit amet, consectetur elit sed do eius mod tempor incidid labore dolore magna aliqua. enim ad minim eniam quis nostrud exercitation ullamco laboris nisi aliquip ex commodo consequat. duis aute irure dolor in repreed ut perspiciatis unde omnis iste natus error sit voluptat em acus antium.</p>
                                <p class="mb-30">doloremque laudantium totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi arch itecto beatae vitae dicta sunt explicabo.</p>
                                <h5 class="teacher_details-title2">Contact Me</h5>
                                <div class="teacher_details-info">
                                    <ul>
                                        <li><span>Address:</span>Hilton Conference Centre</li>
                                        <li><span>Email:</span><a href="mailto:Example@gmail.com">Example@gmail.com</a></li>
                                        <li><span>Phone:</span><a href="tel:+123548645850">+123 548 6458 50</a></li>
                                    </ul>
                                    <div class="teacher_details-social">
                                        <a href="#"><i class="fa-brands fa-twitter"></i></a>
                                        <a href="#"><i class="fa-brands fa-facebook-f"></i></a>
                                        <a href="#"><i class="fa-brands fa-instagram"></i></a>
                                        <a href="#"><i class="fa-brands fa-linkedin-in"></i></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
};

export default Teachers;