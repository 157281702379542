import React from 'react';
import BreadcrumbShape1 from '../assets/img/breadcrumb/shape-1.png';
import BreadcrumbBG from '../assets/img/breadcrumb/breadcrumb-bg.jpg';
import EventMain from '../assets/img/blog/details/1.jpg'; // Main event image
import EventGallery1 from '../assets/img/blog/3/latestarticle1.jpg'; // Gallery images
import EventGallery2 from '../assets/img/blog/3/latestarticle2.jpg';

const Events = () => {
    return (
        <div>
            {/* Breadcrumb Section */}
            <section className="breadcrumb-area bg-default" style={{ backgroundImage: `url(${BreadcrumbBG})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="breadcrumb-title">Event Details</h2>
                                <div className="breadcrumb-list">
                                    <a href="/">Home</a>
                                    <span>Event Details</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Event Details Section */}
            <section className="event_details-area pt-120 pb-60">
                <div className="container">
                    {/* Event Main Image */}
                    <div className="event_details-img mb-50">
                        <img src={EventMain} alt="Guest Lecture: Innovations in Robotics" className="img-fluid" style={{ borderRadius: '8px', boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)' }}/>
                    </div>
                    
                    <div className="row">
                        <div className="col-xl-12 col-lg-12">
                            <div className="event_details-wrap mb-55">
                                <div className="event_details-content">
                                    {/* Event Title */}
                                    <h3 className="event_details-content-title">Guest Lecture: Innovations in Robotics</h3>
                                    
                                    {/* Event Description */}
                                    <p className="mb-25">
                                        Join us for an exciting guest lecture on <strong>Innovations in Robotics</strong>, where Dr. Jane Smith, a leading expert in robotics, will explore the latest trends and breakthroughs in the field. This lecture will cover topics ranging from autonomous robots to AI-driven innovations.
                                    </p>

                                    <p className="mb-45">
                                        Whether you're a robotics enthusiast or a professional looking to expand your knowledge, this event will provide valuable insights into the future of robotics and how it's shaping the world.
                                    </p>

                                    {/* Event Agenda */}
                                    <div className="event_details-content">
                                        <h4>Event Information:</h4>
                                        <ul className="event-info-list">
                                            <li><strong>Date:</strong> October 12th, 2024</li>
                                            <li><strong>Time:</strong> 11:00 AM - 1:00 PM</li>
                                            <li><strong>Location:</strong> Main Auditorium, Tech University</li>
                                            <li><strong>Speaker:</strong> Dr. Jane Smith, Robotics Expert</li>
                                        </ul>
                                    </div>
                                </div>

                                {/* Event Gallery Images */}
                                <div className="event_details-inner-img mt-4">
                                    <img src={EventGallery1} alt="Innovations in Robotics" className="img-fluid mr-4" style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}/>
                                    <img src={EventGallery2} alt="Robotics Technology" className="img-fluid" style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}/>
                                </div>

                                {/* Event Agenda or Additional Information */}
                                <div className="event_details-content mt-4">
                                    <h4>Event Agenda:</h4>
                                    <ul className="event_details-content-list">
                                        <li>11:00 AM - Welcome and Introduction</li>
                                        <li>11:15 AM - Lecture: Innovations in Robotics by Dr. Jane Smith</li>
                                        <li>12:00 PM - Q&A Session</li>
                                        <li>12:30 PM - Networking and Refreshments</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Events;
