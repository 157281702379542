import React, { useState } from 'react';

const AssignmentUpload = () => {
    const [fileName, setFileName] = useState('');
    const [dragActive, setDragActive] = useState(false);

    const handleFileChange = (e) => {
        setFileName(e.target.files[0]?.name || '');
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragActive(true);
    };

    const handleDragLeave = () => {
        setDragActive(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragActive(false);
        setFileName(e.dataTransfer.files[0]?.name || '');
    };

    return (
        <div className="upload-container">
            <div className="upload-card">
                <h2>Submit Your Assignment</h2>
                <form className="upload-form">
                    <div
                        className={`drop-zone ${dragActive ? 'active' : ''}`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        onDrop={handleDrop}
                    >
                        <input
                            type="file"
                            id="file-upload"
                            className="file-input"
                            onChange={handleFileChange}
                        />
                        <label htmlFor="file-upload" className="file-label">
                            <span className="file-icon">📁</span>
                            <p className="file-name">{fileName || 'Drag & Drop your file here or Click to Browse'}</p>
                        </label>
                    </div>
                    <button type="submit" className="upload-button">
                        Upload Assignment
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AssignmentUpload;
