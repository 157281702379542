import React from 'react';
import { Card, CardBody, CardTitle, CardText, Badge } from 'reactstrap'; // Assuming you're using Reactstrap/Bootstrap
import moment from 'moment'; // For date formatting

const SubmittedAssignments = () => {
    // Sample data structure holding submitted assignment details
    const submittedAssignmentsData = [
        {
            subject: "Microprocessor and Microcontroller",
            title: "Understanding the 8085 Microprocessor",
            submittedDate: "2024-09-12",
            status: "On Time",
            grade: "A",
            remarks: "Well done, keep up the good work!"
        },
        {
            subject: "Electronic Device",
            title: "Characteristics of PN Junction Diode",
            submittedDate: "2024-09-13",
            status: "Late",
            grade: "B",
            remarks: "Late submission but good effort."
        },
    
    ];

    const getStatusColor = (status) => {
        if (status === "On Time") return "success"; // Green for on-time submissions
        if (status === "Late") return "warning"; // Yellow for late submissions
        return "secondary"; // Default gray for other cases
    };

    return (
        <div className="submitted-assignments-section">
            <h3 className="text-center mb-4">Submitted Assignments</h3>
            <div className="assignment-cards">
                {submittedAssignmentsData.map((assignment, index) => (
                    <Card key={index} className="mb-4" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <CardBody>
                            <CardTitle tag="h5" className="d-flex justify-content-between align-items-center">
                                {assignment.title}
                                <Badge color={getStatusColor(assignment.status)}>
                                    {assignment.status}
                                </Badge>
                            </CardTitle>
                            <CardText>
                                <strong>Subject:</strong> {assignment.subject} <br/>
                                <strong>Submitted Date:</strong> {moment(assignment.submittedDate).format("MMMM Do, YYYY")} <br/>
                                <strong>Grade:</strong> {assignment.grade} <br/>
                                <strong>Remarks:</strong> {assignment.remarks}
                            </CardText>
                        </CardBody>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default SubmittedAssignments;
