import React, { useState } from 'react';
import Topper1 from "../assets/img/course/3/topper2.jpg";
import UpcomingAssign from './UpcomingAssign';
import SubmittedAssign from './SubmittedAssign'; // Import your SubmittedAssign component
import UploadAssign from './UploadAssign'; // Import your SubmittedAssign component
import AssignmentList from './AssignmentList';

const Courses = () => {
    const [activeTab, setActiveTab] = useState('upcoming'); // Default tab

    // Function to handle tab click
    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div>
            <section className="h8_course-area pt-130 pb-120">
                <img src="assets/img/course/9/shape.png" alt="" className="h8_course-shape"/>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="course_details-top mb-60" style={{marginTop:'-150px'}}>
                            <h3 className="course_details-title">
                                Assignment Details of the Student
                            </h3>
                            <div className="course_details-meta">
                                <div className="course_details-meta-left">
                                    <div className="course_details-author">
                                        <div className="course_details-author-img">
                                            <img
                                                src={Topper1}
                                                alt="Top performer"
                                                className="round-image"
                                            />
                                        </div>
                                        <div
                                            className="course_details-author-info"
                                            style={{
                                                paddingRight: "180px",
                                                paddingLeft: "180px",
                                            }}
                                        >
                                            <span style={{fontSize:"20px"}}>Student Name</span>
                                            <h5 style={{fontSize:"18px"}}>
                                                <a href="team.html">Ramesh</a>
                                            </h5>
                                        </div>
                                    </div>
                                    <div
                                        className="course_details-author-info"
                                        style={{ paddingRight: "180px" }}
                                    >
                                        <span style={{fontSize:"20px"}}>Year/Dept</span>
                                        <h5 style={{fontSize:"18px"}}>
                                            <a href="team.html">II-ECE</a>
                                        </h5>
                                    </div>
                                    <div
                                        className="course_details-author-info"
                                        style={{ paddingRight: "180px" }}
                                    >
                                        <span style={{fontSize:"20px"}}>StudentId</span>
                                        <h5 style={{fontSize:"18px"}}>
                                            <a href="team.html">964123</a>
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-8 col-lg-8 col-md-8">
                            <div className="section-area-8">
                                <h2 className="section-title mb-0">Assignment Details</h2>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 d-none">
                            <div className="h8_blog-navigation mb-30">
                                <div className="h8_blog-prev">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M13 7H1" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7 1L1 7L7 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                                <div className="h8_blog-next">
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 7H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        <path d="M7 1L13 7L7 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="h8_course-active pt-50">
                        <div className="h8_course-wrap horizontal-accordion">
                            <div
                                className={`h8_course-item h8_course_common ${activeTab === 'upcoming' ? 'expand' : ''} h8_item_1`}
                                onClick={() => handleTabClick('upcoming')}
                            >
                                <div className="h8_course-m-title">
                                    <h6 className="h8_course-m-count">
                                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 1L23 7L17 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.7" d="M9 1L15 7L9 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.5" d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </h6>
                                    <span className="h8_course-m-title-vertical">Upcoming Assignments</span>
                                    <h6 className="h8_course-m-count h8_course-m-count-2">
                                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 1L23 7L17 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.7" d="M9 1L15 7L9 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.5" d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </h6>
                                </div>
                                <div className="h8_course-content-3">
                                    <div className="course-accordion-wrap">
                                        <div className="accordion-body-bottom">
                                            <div className="accordion-body-content">
                                                <UpcomingAssign/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div
                                className={`h8_course-item h8_course_common ${activeTab === 'submitted' ? 'expand' : ''} h8_item_2`}
                                onClick={() => handleTabClick('submitted')}
                            >
                                <div className="h8_course-m-title">
                                    <h6 className="h8_course-m-count">
                                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 1L23 7L17 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.7" d="M9 1L15 7L9 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.5" d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </h6>
                                    <span className="h8_course-m-title-vertical">Submitted Assignments</span>
                                    <h6 className="h8_course-m-count h8_course-m-count-2">
                                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 1L23 7L17 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.7" d="M9 1L15 7L9 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.5" d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </h6>
                                </div>
                                <div className="h8_course-content-3">
                                    <div className="course-accordion-wrap">
                                        <div className="accordion-body-bottom">
                                            <div className="accordion-body-content">
                                                <SubmittedAssign/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`h8_course-item h8_course_common ${activeTab === 'upload' ? 'expand' : ''} h8_item_2`}
                                onClick={() => handleTabClick('upload')}
                            >
                                <div className="h8_course-m-title">
                                    <h6 className="h8_course-m-count">
                                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 1L23 7L17 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.7" d="M9 1L15 7L9 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.5" d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </h6>
                                    <span className="h8_course-m-title-vertical">Upload Assignments</span>
                                    <h6 className="h8_course-m-count h8_course-m-count-2">
                                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 1L23 7L17 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.7" d="M9 1L15 7L9 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.5" d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </h6>
                                </div>
                                <div className="h8_course-content-3">
                                    <div className="course-accordion-wrap">
                                        <div className="accordion-body-bottom">
                                            <div className="accordion-body-content">
                                                <UploadAssign/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`h8_course-item h8_course_common ${activeTab === 'assignmentlist' ? 'expand' : ''} h8_item_2`}
                                onClick={() => handleTabClick('assignmentlist')}
                            >
                                <div className="h8_course-m-title">
                                    <h6 className="h8_course-m-count">
                                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 1L23 7L17 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.7" d="M9 1L15 7L9 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.5" d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </h6>
                                    <span className="h8_course-m-title-vertical">Assignment List</span>
                                    <h6 className="h8_course-m-count h8_course-m-count-2">
                                        <svg width="24" height="14" viewBox="0 0 24 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17 1L23 7L17 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.7" d="M9 1L15 7L9 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path opacity="0.5" d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </h6>
                                </div>
                                <div className="h8_course-content-3">
                                    <div className="course-accordion-wrap">
                                        <div className="accordion-body-bottom">
                                            <div className="accordion-body-content">
                                                <AssignmentList/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Courses;
