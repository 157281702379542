import React from 'react';
import { Card, CardBody, CardTitle, CardText, Badge } from 'reactstrap'; // Assuming you're using Bootstrap/Reactstrap
import moment from 'moment'; // For date formatting and manipulation

const UpcomingAssignments = () => {
    // Sample data structure holding assignment details
    const assignmentsData = [
        {
            subject: "Microprocessor and Microcontroller",
            title: "Understanding the 8085 Microprocessor",
            dueDate: "2024-09-20",
            description: "Draw the architecture of the 8085 microprocessor and explain each component",
        },
        {
            subject: "Electronic Device",
            title: "Characteristics of PN Junction Diode",
            dueDate: "2024-09-18",
            description: "Study the characteristics of a PN junction diode in forward and reverse bias",
        },
     
    ];

    const getDueInDays = (dueDate) => {
        const now = moment();
        const due = moment(dueDate);
        return due.diff(now, 'days');
    };

    const getUrgencyColor = (dueDate) => {
        const daysLeft = getDueInDays(dueDate);
        if (daysLeft <= 2) return "danger"; // Red for urgent assignments (due in 2 days or less)
        if (daysLeft <= 5) return "warning"; // Yellow for moderate urgency (due in 5 days or less)
        return "success"; // Green for assignments that are not urgent
    };

    return (
        <div className="upcoming-assignments-section">
            <h3 className="text-center mb-4">Upcoming Assignments</h3>
            <div className="assignment-cards">
                {assignmentsData.map((assignment, index) => (
                    <Card key={index} className="mb-4" style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
                        <CardBody>
                            <CardTitle tag="h5" className="d-flex justify-content-between align-items-center">
                                {assignment.title}
                                <Badge color={getUrgencyColor(assignment.dueDate)}>
                                    Due in {getDueInDays(assignment.dueDate)} days
                                </Badge>
                            </CardTitle>
                            <CardText>
                                <strong>Subject:</strong> {assignment.subject} <br/>
                                <strong>Due Date:</strong> {moment(assignment.dueDate).format("MMMM Do, YYYY")} <br/>
                                <strong>Description:</strong> {assignment.description}
                            </CardText>
                        </CardBody>
                    </Card>
                ))}
            </div>
        </div>
    );
};

export default UpcomingAssignments;
