import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';

// Register the required components for chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const ExamResultStats = () => {
    const examPercentages = [85, 92, 75, 88]; // Example overall percentages

    // Function to dynamically assign colors based on percentage
    const getColorForPercentage = (percentage) => {
        if (percentage >= 80) return '#C70039'; // Green for high marks
        if (percentage >= 60) return ' #FF5733   '; // Yellow for medium marks
        return '#FFC300'; // Red for low marks
    };

    // Generate background colors dynamically for each bar
    const backgroundColors = examPercentages.map((percentage) => getColorForPercentage(percentage));

    const examData = {
        labels: ['Final Exam', 'Internal Exam-3', 'Internal Exam-2', 'Internal Exam-1'], // Example exam names
        datasets: [
            {
                label: 'Overall Percentage',
                data: examPercentages, // Example overall percentages
                backgroundColor: backgroundColors, // Dynamic color for each bar
                borderColor: 'transparent',
                borderWidth: 1,
            },
        ],
    };

    const examOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                beginAtZero: true,
                max: 100, // Maximum value is 100 for percentage
                ticks: {
                    callback: function (value) {
                        return value + '%'; // Show percentage sign
                    },
                },
            },
        },
    };

    return (
        <div className="exam-results-container">
            <div className="row">
                <div className="col-md-6">
                    <div className="exam-chart-container">
                        <h3 className="chart-title">Exam Performance</h3>
                        <div className="exam-chart">
                            <Bar data={examData} options={examOptions} />
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="exam-card-container">
                        <div className="exam-card">
                            <h5>Final Exam</h5>
                            <p><strong>85%</strong></p>
                        </div>
                        <div className="exam-card">
                            <h5>Internal Exam-1</h5>
                            <p><strong>92%</strong></p>
                        </div>
                        <div className="exam-card">
                            <h5>Internal Exam-2</h5>
                            <p><strong>75%</strong></p>
                        </div>
                        <div className="exam-card">
                            <h5>Internal Exam-3</h5>
                            <p><strong>88%</strong></p>
                        </div>
                        <div className="overall-card">
                            <h5>Overall Average</h5>
                            <p><strong>85%</strong></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ExamResultStats;
