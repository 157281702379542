import React from 'react';
import BreadcrumbShape1 from '../assets/img/breadcrumb/shape-1.png';
import BreadcrumbBG from '../assets/img/breadcrumb/breadcrumb-bg.jpg';
import Line from '../assets/img/banner/2/line.png';
import InnerPage1 from '../assets/img/gallery/innerPage/1.jpg';
import InnerPage2 from '../assets/img/gallery/innerPage/2.jpg';
import InnerPage3 from '../assets/img/gallery/innerPage/3.jpg';
import InnerPage4 from '../assets/img/gallery/innerPage/4.jpg';
import InnerPage5 from '../assets/img/gallery/innerPage/5.jpg';
import InnerPage6 from '../assets/img/gallery/innerPage/6.jpg';
import InnerPage7 from '../assets/img/gallery/innerPage/7.jpg';
import InnerPage8 from '../assets/img/gallery/innerPage/8.jpg';
import InnerPage9 from '../assets/img/gallery/innerPage/9.jpg';

const Teachers = () => {
    return (
        <div>
            <section class="breadcrumb-area bg-default" data-background={BreadcrumbBG}style={{ backgroundImage: `url(${BreadcrumbBG})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <img src={BreadcrumbShape1} alt="" class="breadcrumb-shape"/>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <div class="breadcrumb-content">
                                <h2 class="breadcrumb-title">Gallery</h2>
                                <div class="breadcrumb-list">
                                    <a href="index.html">Home</a>
                                    <span>Gallery</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="innerPage_gallery-area pt-110 pb-90">
                <div class="container">
                    <div class="row align-items-end">
                        <div class="col-xl-5 col-lg-6">
                            <div class="section-area-2">
                                <h2 class="section-title mb-50">Browse Our
                                    <br/> Exclusive  <span>Gallery <img src={Line} t=""/></span>
                                </h2>
                            </div>
                        </div>
                        <div class="col-xl-7 col-lg-6">
                            <div class="innerPage_gallery-tab mb-40">
                                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                      <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">See All
                                        <span>New</span></button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                      <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Courses</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                      <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Events</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                      <button class="nav-link" id="pills-four-tab" data-bs-toggle="pill" data-bs-target="#pills-four" type="button" role="tab" aria-controls="pills-four" aria-selected="false">Students</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                      <button class="nav-link" id="pills-five-tab" data-bs-toggle="pill" data-bs-target="#pills-five" type="button" role="tab" aria-controls="pills-five" aria-selected="false">Teachers</button>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="innerPage_gallery-wrap">
                        <div class="tab-content" id="pills-tabContent">
                            <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab" tabindex="0">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage2} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage2} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage3} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage3} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage4} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage4} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage5} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage5} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage6} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage6} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage7} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage7} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage8} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage8} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage9} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage9} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab" tabindex="0">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab" tabindex="0">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-four" role="tabpanel" aria-labelledby="pills-four-tab" tabindex="0">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="pills-five" role="tabpanel" aria-labelledby="pills-five-tab" tabindex="0">
                                <div class="row">
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src="assets/img/gallery/innerPage/6.jpg" alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                        <div class="innerPage_gallery-item mb-30">
                                            <div class="innerPage_gallery-img">
                                                <img src={InnerPage1} alt=""/>
                                            </div>
                                            <div class="innerPage_gallery-content">
                                                <a href={InnerPage1} class="popup-image"><i class="fa-thin fa-plus"></i></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Teachers;