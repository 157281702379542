import React from 'react';
import Logo from '../assets/img/logo/United Logo.png';

const MainContent = () => {
    return (
        <div class="h3_header-area header-sticky">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-xl-3 col-sm-7 col-6">
                    <div class="h3_header-logo">
                        <a href="index.html"><img src={Logo} alt="logo"/></a>
                    </div>
                </div>
                <div class="col-xl-6 d-none d-xl-block">
                    <div class="h3_header-middle">
                        <nav class="h3_main-menu mobile-menu" id="mobile-menu">
                            <ul>
                            <li><a href="#/Home">Home</a></li>
                            <li><a href="#/Attendance">Attendance</a></li>
                            <li><a href="#/Assignment">Assignments</a></li>
                            <li><a href="#/Results">Results</a></li>
                            <li><a href="#/Events">Events</a></li>
                            <li><a href="#/HolidayList">Holidays</a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
                <div class="col-xl-3 col-sm-5 col-6">
                    <div class="h3_header-right">
                        <div class="h3_header-btn d-none d-sm-block">
                            <a href="/Signup" class="header-btn theme-btn theme-btn-medium theme-btn-3">Sign Up Now<i class="fa-light fa-arrow-up-right"></i></a>
                        </div>
                        <div class="header-menu-bar d-xl-none ml-10">
                            <span class="header-menu-bar-icon side-toggle">
                                <i class="fa-light fa-bars"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    );
};

export default MainContent;