import React from 'react';
import Logo from '../assets/img/logo/United Logo.png';

const MainContent = () => {
    return (
     <div>
         <div class="cta-area h3_cta-area">
                <div class="container">
                    <div class="cta-wrapper">
                        <div class="row align-items-center">
                            <div class="col-xl-6 col-lg-6">
                                <div class="cta-content mb-30 mb-lg-0">
                                    <span class="cta-subtitle">Download App</span>
                                    <h2 class="cta-title">Are you Ready to Start</h2>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-6">
                                <div class="cta-button">
                                    <a href="#" class="cta-btn"><i class="fa-brands fa-apple"></i>Apple Store</a>
                                    <a href="#" class="cta-btn"><i class="fa-brands fa-google-play"></i>Play Store</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  
    <footer class="footer-area h3_footer-area">
        <div class="footer-top pt-240 pb-55">
            <div class="container">
               <div class="row justify-content-between">
                    <div class="col-xl-6 col-lg-8 col-md-8 col-sm-12">
                        <div class="footer-widget mb-40">
                            <div class="footer-logo">
                                <a href="index.html"><img src={Logo} alt="" height="20%" width="25%"/></a>
                            </div>
                            <p class="footer-widget-text mb-35">
                                Through a combination of lectures, readings, discussions, students will gain solid foundation in educational.
                            </p>
                            <div class="footer-social">
                                <ul>
                                    <li><a href="#"><i class="fa-brands fa-twitter"></i></a></li>
                                    <li><a href="#"><i class="fa-brands fa-facebook-f"></i></a></li>
                                    <li><a href="#"><i class="fa-brands fa-linkedin-in"></i></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                  
                
                    <div class="col-xl-5 col-lg-8 col-md-8 col-sm-12 order-md-4 order-xl-5">
                        <div class="footer-widget ml-80 mb-40">
                            <h5 class="footer-widget-title">Newsletter</h5>
                            <p class="footer-widget-text mb-20 newsletter-text">Sign up for our newsletter <br/> off your next course.</p>
                            <form action="#">
                                <div class="footer-subscribe-form">
                                    <input type="email" placeholder="Enter Your Email*"/>
                                    <button type="submit">Subscribe</button>
                                </div>
                                <div class="footer-subscribe-condition">
                                    <label class="condition_label">I agree to the terms of use and privacy policy.
                                        <input type="checkbox"/>
                                        <span class="check_mark"></span>
                                    </label>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container">
            <div class="copyright-area">
                <div class="row">
                    <div class="col-12">
                        <div class="copyright-text">
                            <p>Copyright © 2023 All Rights Reserved by Eduan</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </div>
    );    
};

export default MainContent;