import './App.css';
import { HashRouter, Route, Routes } from 'react-router-dom';
import './assets/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/css/magnific-popup.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/odometer.min.css';
import './assets/css/nice-select.css';
import './assets/css/meanmenu.css';
import './assets/css/swiper-bundle.min.css';
import './assets/css/main.css';
import Home from '../src/components/Home';
import Header from '../src/components/Header';
import  Footer from '../src/components/Footer';
import Attendance from '../src/components/Attendance';
import Assignment from './components/Assignment';
import Results from './components/Results';
import TeacherDetails from './components/TeacherDetails';
import Events from './components/Events';
import EventDetails from './components/EventDetails';
import Gallery from './components/Gallery';
import HolidayList from './components/HolidayList';
import Articles from './components/Articles';
import Signup from './components/Signup';
import Signin from './components/Signin';
import AssignmentDetails from './components/AssignmentDetails';

function App() {
  return (
    <HashRouter>
      <Header/>
      <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Home" element={<Home />} />
      <Route path="/Attendance" element={<Attendance />} />      
      <Route path="/Assignment" element={<Assignment />} />      
      <Route path="/Results" element={<Results />} />      
      <Route path="/TeacherDetails" element={<TeacherDetails />} />      
      <Route path="/Events" element={<Events />} />      
      <Route path="/EventDetails" element={<EventDetails />} />  
      <Route path="/Gallery" element={<Gallery />} />          
      <Route path="/HolidayList" element={<HolidayList />} />          
      <Route path="/Articles" element={<Articles />} />          
      <Route path="/Signup" element={<Signup />} />          
      <Route path="/Signin" element={<Signin />} />          
      <Route path="/assignment/:id" element={<AssignmentDetails />} />
      
      </Routes>
       <Footer/>
</HashRouter>          
  );
}

export default App;
