import React from 'react';

const StaffAdvice = () => {
    const subjects = [
        {
            subject: 'Digital Electronics',
            advice: 'Focus on mastering Boolean algebra and Karnaugh maps as these are essential for simplifying logic circuits.',
            staffName: 'Prof. Rahul Verma'
        },
        {
            subject: 'Signals and Systems',
            advice: 'Understand the basic concepts of signal transformations, both in time and frequency domains.',
            staffName: 'Dr. Sneha Iyer'
        },
        {
            subject: 'Electromagnetic Theory',
            advice: 'Pay special attention to vector calculus and its application in electromagnetics. ',
            staffName: 'Prof. Anil K. Gupta'
        },
        {
            subject: 'Analog Circuits',
            advice: 'Start with a strong foundation in semiconductor physics and the working of diodes and transistors.',
            staffName: 'Dr. Meena Reddy'
        },
        {
            subject: 'Communication Systems',
            advice: 'Focus on the fundamentals of analog and digital modulation techniques such as AM, FM, and PCM.',
            staffName: 'Dr. Meena Reddy'
        },
        {
            subject: 'Microprocessors and Microcontrollers',
            advice: 'Begin by understanding the architecture of microprocessors and microcontrollers.',
            staffName: 'Prof. Ravi Shankar'
        },

    ];

    return (
        <div className="staff-advice-container">
            <h1 className="page-title">Subject-wise Staff Advice</h1>
            <div className="advice-cards">
                {subjects.map((subject, index) => (
                    <div key={index} className="advice-card">
                        <h2 className="subject-title">{subject.subject}</h2>
                        <p className="staff-advice-text">{subject.advice}</p>
                        <p className="staff-name">- {subject.staffName}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default StaffAdvice;
