import React from 'react';

const ProgressReport = () => {
    const subjects = [
        { name: 'Digital Electronics', marks: 85, grade: 'A' },
        { name: 'Signals and Systems', marks: 78, grade: 'B+' },
        { name: 'Electromagnetic Theory', marks: 92, grade: 'A+' },
        { name: 'Microprocessors and Microcontrollers', marks: 74, grade: 'B' },
        { name: 'VLSI Design System', marks: 88, grade: 'A' },
        { name: 'Wireless Communication System', marks: 92, grade: 'A+' },

    ];

    // Function to calculate the overall average marks
    const calculateAverage = () => {
        const totalMarks = subjects.reduce((sum, subject) => sum + subject.marks, 0);
        return (totalMarks / subjects.length).toFixed(2);
    };

    return (
        <div className="progress-report-container">
            <h2 className="report-title">Recent Exam Progress Report</h2>
            <div className="progress-cards">
                {subjects.map((subject, index) => (
                    <div className="progress-card" key={index}>
                        <div className="card-header" style={{ backgroundColor: getColor(subject.marks) }}>
                            <h3 className="subject-name">{subject.name}</h3>
                        </div>
                        <div className="card-body">
                            <p className="subject-marks">Marks: <strong>{subject.marks}%</strong></p>
                            <p className="subject-grade">Grade: <strong>{subject.grade}</strong></p>
                        </div>
                      
                    </div>
                ))}
            </div>
            <div className="overall-average">
                <h4>Overall Average: <strong>{calculateAverage()}%</strong></h4>
            </div>
        </div>
    );
};

// Function to get color based on marks (for color coding)
const getColor = (marks) => {
    if (marks >= 90) return '#4caf50'; // Green for excellent
    if (marks >= 80) return '#8bc34a'; // Light Green for good
    if (marks >= 70) return '#ffeb3b'; // Yellow for average
    return '#f44336'; // Red for below average
};

export default ProgressReport;
