import React from 'react';
import BreadcrumbShape1 from '../assets/img/breadcrumb/shape-1.png';
import BreadcrumbBG from '../assets/img/breadcrumb/breadcrumb-bg.jpg';

const holidays = [
    {
        name: 'New Year',
        date: 'January 1, 2024',
        description: 'Celebration of the new year.',
    },
    {
        name: 'Independence Day',
        date: 'July 4, 2024',
        description: 'Celebration of the independence of the country.',
    },
    {
        name: 'Christmas',
        date: 'December 25, 2024',
        description: 'Celebration of Christmas Day.',
    },
    {
        name: 'Labor Day',
        date: 'September 2, 2024',
        description: 'A day to honor workers and their contributions.',
    },
    {
        name: 'Thanksgiving',
        date: 'November 28, 2024',
        description: 'A day to give thanks and celebrate with family.',
    },
];

const HolidayListTable = () => {
    return (
        <div>
            {/* Breadcrumb Area */}
            <section className="breadcrumb-area bg-default" style={{ backgroundImage: `url(${BreadcrumbBG})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="breadcrumb-content">
                                <h2 className="breadcrumb-title">Holiday List</h2>
                                <div className="breadcrumb-list">
                                    <a href="index.html">Home</a>
                                    <span>Holiday List</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            
            {/* Holiday Table */}
            <section className="holiday-list-area pt-120 pb-90">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 text-center">
                            <h2 className="section-title mb-40">Upcoming Holidays</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="holiday-table-container">
                                <table className="holiday-table">
                                    <thead>
                                        <tr>
                                            <th>Holiday Name</th>
                                            <th>Date</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {holidays.map((holiday, index) => (
                                            <tr key={index}>
                                                <td>{holiday.name}</td>
                                                <td>{holiday.date}</td>
                                                <td>{holiday.description}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default HolidayListTable;
