import React from "react";
import Shape2 from "../assets/img/banner/3/shape-2.png";
import Shape3 from "../assets/img/banner/3/shape-3.png";
import Shape1 from "../assets/img/banner/3/shape-1.png";
import Banner from "../assets/img/admission/3/banner.png";
import About from "../assets/img/about/3/About.png";
import About1 from "../assets/img/about/3/shape-4.png";
import About2 from "../assets/img/about/3/shape-5.png";
import AboutShape1 from "../assets/img/about/3/shape-1.png";
import AboutShape2 from "../assets/img/about/3/shape-2.png";
import AboutShape3 from "../assets/img/about/3/shape-3.png";
import Topper1 from "../assets/img/course/3/topper1.jpg";
import Topper2 from "../assets/img/course/3/topper2.jpg";
import Topper3 from "../assets/img/course/3/topper3.jpg";
import Admission from "../assets/img/admission/3/banner.png";
import AdmissionShape1 from "../assets/img/admission/3/shape-1.png";
import AdmissionShape3 from "../assets/img/admission/3/shape-3.png";
import AdmissionShape2 from "../assets/img/admission/3/shape-2.png"

const HomeContents = () => {
  return (
    <div>
      <section class="h3_banner-area">
        <div class="h3_single-banner">
          <div class="container">
            <div class="row align-items-center">
              <div class="col-xl-7 col-lg-6 col-md-12">
                <div class="h3_banner-content">
                  <div class="section-area-3 mb-45 ">
                    <span class="section-subtitle">
                      🏆 Welcome Jorge
                    </span>
                    <h1 class="section-title">
                    You're <span>One Step</span> Closer to Success! Keep Track of Your<span>Progress.</span> 
                    </h1>
                    <p class="section-text">
                    Manage Fees, Attendance, and Deadlines with Ease
                    </p>
                  </div>
                 
                  <img
                    class="h3_banner-content-shape-1"
                    src={Shape1}
                    alt="shape1"
                  />
                </div>
              </div>
              <div class="col-xl-5 col-lg-6 d-none d-lg-block">
                <div class="h3_banner-right">
                  <div class="h3_banner-img">
                    <img src={Banner} alt="banner" />
                  </div>
                  <div class="h3_banner-right-shape">
                    <div class="inner-shpae-1">
                      <img
                        class="h3_banner-shape-2"
                        src={Shape2}
                        alt="shape2"
                      />
                    </div>
                    <img class="h3_banner-shape-1" src={Shape3} alt="shape3" />
                  </div>
                  <div class="h3_banner-meta">
                    <div class="h3_banner-meta-info">
                      <span>DeadLine for Assignment</span>
                      <h5>18-09-2024</h5>
                    </div>
                    <div class="h3_banner-meta-rating">
                      <span>
                        <i class="fa-solid fa-star"></i>Upcoming Exam
                      </span>
                      <h5>15-09-2024</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* about area */}
      <section class="h3_about-area pt-140 pb-90">
        <img src={About2} alt="" class="h3_about-top-shape" />
        <div class="container">
          <div class="row align-items-center">
            <div class="col-xl-6 col-lg-6">
              <div class="h3_about-img mb-50">
                <div class="h3_about-inner-img w_img mr-50">
                  <img src={About} alt="" />
                </div>
                <div class="h3_about-img-shape d-none d-sm-block">
                  <img class="h3_about-img-shape-1" src={AboutShape1} alt="" />
                  <img class="h3_about-img-shape-2" src={AboutShape2} alt="" />
                  <img class="h3_about-img-shape-3" src={AboutShape3} alt="" />
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12">
              <div class="h3_about-wrap mr-65 mb-50">
                <img src={About1} alt="" class="h3_about-wrap-shape" />
                <div class="section-area-3 mb-35 small-section-area-3">
                  <span class="section-subtitle">Upcoming Assignment</span>
                  <h2 class="section-title mb-25">
                    FIR Filter Design using MATLAB
                  </h2>
                  <p class="section-text">
                    Design a Finite Impulse Response (FIR) filter using MATLAB
                    for low-pass filtering. Perform frequency response analysis
                    and plot the magnitude and phase response of the designed
                    filter. Submit your MATLAB code, results, and a brief report
                    explaining the design process and filter performance.{" "}
                  </p>
                </div>
                <div class="h3_about-content mb-35">
                  <div class="row">
                    <div class="col-sm-6">
                      <span>
                        <i class="fa-regular fa-check"></i>Subject: DSP
                      </span>
                    </div>
                    <div class="col-sm-6">
                      <span>
                        <i class="fa-regular fa-check"></i>Staff: Ms.Swetha
                      </span>
                    </div>
                    <div class="col-sm-6">
                      <span>
                        <i class="fa-regular fa-check"></i>Due Date: 09/10/2024
                      </span>
                    </div>
                    <div class="col-sm-6">
                      <span>
                        <i class="fa-regular fa-check"></i>Status: Pending
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Time table*/}
      <section class="h3_category-area pt-135 pb-110">
        <div class="container">
          <div class="row align-items-end mb-30">
            <div class="col-md-9">
              <div class="section-area-3 mb-30">
                <span class="section-subtitle" style={{ textAlign: "left" }}>
                  Upcoming Exams
                </span>
                <h2 class="section-title mb-0" style={{ textAlign: "left" }}>
                  Time Table
                </h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 col-lg-6">
              <div class="h3_category-item mb-30">
                <div class="h3_category-item-icon">
                  <i class="fa-light fa-square-pen"></i>
                </div>
                <div class="h3_category_inner">
                  <div class="h3_category-item-content">
                    <h5 style={{fontWeight:'bold'}}>
                     Internal Exam
                    </h5>
                    <ul>
                              <li style={{fontSize:'16px'}}>
                              <strong>Subject:</strong> Digital Signal Processing
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>Date:</strong> 15-09-2024
                              </li>
                             
                            </ul>
                  </div>
               
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
            <div class="h3_category-item mb-30">
                <div class="h3_category-item-icon">
                  <i class="fa-light fa-square-pen"></i>
                </div>
                <div class="h3_category_inner">
                  <div class="h3_category-item-content">
                  <h5 style={{fontWeight:'bold'}}>
                     Internal Exam
                    </h5>
                    <ul>
                              <li style={{fontSize:'16px'}}>
                              <strong>Subject:</strong> Microprocessor and Micro Controller
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>Date:</strong> 17-09-2024
                              </li>
                             
                            </ul>
                  </div>
               
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
            <div class="h3_category-item mb-30">
                <div class="h3_category-item-icon">
                  <i class="fa-light fa-square-pen"></i>
                </div>
                <div class="h3_category_inner">
                  <div class="h3_category-item-content">
                  <h5 style={{fontWeight:'bold'}}>
                     Internal Exam
                    </h5>
                    <ul>
                              <li style={{fontSize:'16px'}}>
                              <strong>Subject:</strong> Object-Oriented Programming System
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>Date:</strong> 19-09-2024
                              </li>
                             
                            </ul>
                  </div>
               
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
            <div class="h3_category-item mb-30">
                <div class="h3_category-item-icon">
                  <i class="fa-light fa-square-pen"></i>
                </div>
                <div class="h3_category_inner">
                  <div class="h3_category-item-content">
                  <h5 style={{fontWeight:'bold'}}>
                     Internal Exam
                    </h5>
                    <ul>
                              <li style={{fontSize:'16px'}}>
                              <strong>Subject:</strong> Circuit Theory
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>Date:</strong> 22-09-2024
                              </li>
                             
                            </ul>
                  </div>
               
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
            <div class="h3_category-item mb-30">
                <div class="h3_category-item-icon">
                  <i class="fa-light fa-square-pen"></i>
                </div>
                <div class="h3_category_inner">
                  <div class="h3_category-item-content">
                  <h5 style={{fontWeight:'bold'}}>
                     Internal Exam
                    </h5>
                    <ul>
                              <li style={{fontSize:'16px'}}>
                              <strong>Subject:</strong> Electronic Device
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>Date:</strong> 24-09-2024
                              </li>
                             
                            </ul>
                  </div>
               
                </div>
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
            <div class="h3_category-item mb-30">
                <div class="h3_category-item-icon">
                  <i class="fa-light fa-square-pen"></i>
                </div>
                <div class="h3_category_inner">
                  <div class="h3_category-item-content">
                  <h5 style={{fontWeight:'bold'}}>
                     Internal Exam
                    </h5>
                    <ul>
                              <li style={{fontSize:'16px'}}>
                              <strong>Subject:</strong> Signal Processing
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>Date:</strong> 26-09-2024
                              </li>
                             
                            </ul>
                  </div>
               
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* attendance summary */}

      <section class="h5_event-area h6_event-area pt-115 pb-120">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="section-area-6 text-center mb-60">
                <span class="section-subtitle">Attendance</span>
                <h2 class="section-title mb-0">Attendance Summary</h2>
              </div>
            </div>
          </div>
          <div class="row g-0">
            <div class="col-xl-10" style={{marginLeft:'100px'}}>
              <div class="h5_event-wrap">
                <div class="h5_event-item">
                  <div class="h5_event-item-date">
                    <h1>03</h1>
                    <p>March, 2023</p>
                  </div>
                  <div class="h5_event-item-content">
                    <span
                      class="section-subtitle"
                      style={{ color: "red", textAlign: "center", paddingBottom:'25px' }}
                    >
                      Last Month
                    </span>

                    <h5>
                      <a
                        href="event-details.html"
                        class="d-flex justify-content-between"
                      >
                        <span>Attendance: 82%</span>
                        <span>Classes Attended: 98/120</span>
                        <span>Status: Good</span>
                      </a>
                    </h5>
                  </div>
                </div>
                <div class="h5_event-item">
                  <div class="h5_event-item-date">
                    <h1>24</h1>
                    <p>May, 2023</p>
                  </div>
                  <div class="h5_event-item-content">
                    <span
                      class="section-subtitle"
                      style={{ color: "red", textAlign: "center", paddingBottom:'25px' }}
                    >
                      Best Month
                    </span>

                    <h5>
                      <a
                        href="event-details.html"
                        class="d-flex justify-content-between"
                      >
                        <span>Attendance: 95%</span>
                        <span>Classes Attended: 30/40</span>
                        <span>Status: Excellent</span>
                      </a>
                    </h5>
                  </div>
                </div>
                <div class="h5_event-item">
                  <div class="h5_event-item-date">
                    <h1>18</h1>
                    <p>June, 2023</p>
                  </div>
                  <div class="h5_event-item-content">
                    <span
                      class="section-subtitle"
                      style={{ color: "red", textAlign: "center", paddingBottom:'25px' }}
                    >
                      Overall Attendance
                    </span>
                  
                    <h5>
                      <a
                        href="event-details.html"
                        class="d-flex justify-content-between"
                      >
                        <span>Attendance: 82%</span>
                        <span>Classes Attended: 98/120</span>
                        <span>Status: Good</span>
                      </a>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Fee summary */}
      <section class="h6_tuition-area pt-120 pb-70">
                <div class="container">
                    <div class="row g-0">
               
                        <div class="col-xl-5">
                            <div class="h6_tuition-content mb-45">
                                <img src="assets/img/bg/tuition-logo.png" alt=""/>
                                <h2>Fees Summary</h2>
                                <p>Your fees status for the academic year is displayed below to help you <br/> stay informed about yout financial obligations.We encourage you to<br/>  review the details regularly to avoid any overdue payments.</p>
                                <a href="#">See full fee structure<i class="fa-light fa-arrow-right"></i></a>
                            </div>
                        </div>
                        <div class="col-xl-7">
                            <div class="h6_tuition-wrap mb-50">
                                <div class="h6_tuition-item">
                                    <span class="h6_tuition-item-date">2024-2025</span>
                                    <h4 class="h6_tuition-item-title">Tuition Fee</h4>
                                    <ul class="h6_tuition-item-list">
                                        <li>Total Amount<span>15,000</span></li>
                                        <li>Amount Paid<span>10,000</span></li>
                                        <li>Pending Amount<span>5,000</span></li>
                                        <li>Due Date<span>25-09-2024</span></li>
                                        <li>Payment Status<span>Parially Paid</span></li>
                                    </ul>
                                    <p class="h6_tuition-item-total"><span>Total :</span> <span>15,000</span></p>
                                </div>
                                <div class="h6_tuition-item h6_tuition-light">
                                    <span class="h6_tuition-item-date">2024-2025</span>
                                    <h4 class="h6_tuition-item-title">Hostel Fee</h4>
                                    <ul class="h6_tuition-item-list">
                                    <li>Total Amount<span>25,000</span></li>
                                        <li>Amount Paid<span>15,000</span></li>
                                        <li>Pending Amount<span>10,000</span></li>
                                        <li>Due Date<span>30-09-2024</span></li>
                                        <li>Payment Status<span>Parially Paid</span></li>
                                    </ul>
                                    <p class="h6_tuition-item-total"><span>Total :</span> <span>25,000</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
      {/* Course area */}
      <section class="h3_course-area pt-135 pb-110">
        <div class="container">
          <div class="row">
            <div class="col-xl-12">
              <div class="section-area-3 text-center mb-40">
                <h2 class="section-title mb-0">Toppers</h2>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12">
              <div class="h3_course-tab mb-40">
                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                     UG
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      PG
                    </button>
                  </li>
                  <li class="nav-item" role="presentation">
                    <button
                      class="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      PHD
                    </button>
                  </li>
               
               
                </ul>
              </div>
            </div>
          </div>
          <div class="h3_course-wrap">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabindex="0"
              >
                <div class="row">
                  <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="h3_course-item mb-30">
                      <div class="h3_course-item-top">
                        <div class="h3_course-item-img">
                          <a href="course-details.html">
                            <img src={Topper1} alt="" />
                          </a>
                          <div class="h3_course-item-tag">
                            <a href="#">1st</a>
                          </div>
                          <div class="h3_course-item-save">
                            <a href="#">
                              <i class="fa-light fa-bookmark"></i>
                            </a>
                          </div>
                        </div>
                        <div class="h3_course-content">
                          <div class="h3_course-content-info">
                            <ul>
                              <li style={{fontSize:'16px'}}>
                              <strong>Name:</strong> Kiruthika
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>Department:</strong> CSE
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>CGPA:</strong> 9.5
                              </li>
                            </ul>
                          </div>
                        
                        </div>
                      </div>
                     
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="h3_course-item mb-30">
                      <div class="h3_course-item-top">
                        <div class="h3_course-item-img">
                          <a href="course-details.html">
                            <img src={Topper2} alt="" />
                          </a>
                          <div class="h3_course-item-tag">
                            <a href="#">2nd</a>
                          </div>
                          <div class="h3_course-item-save">
                            <a href="#">
                              <i class="fa-light fa-bookmark"></i>
                            </a>
                          </div>
                        </div>
                        <div class="h3_course-content">
                          <div class="h3_course-content-info">
                            <ul>
                              <li style={{fontSize:'16px'}}>
                              <strong>Name:</strong> Ramesh
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>Department:</strong> ECE
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>CGPA:</strong> 9.8
                              </li>
                            </ul>
                          </div>
                        
                        </div>
                      </div>
                   
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-6 col-md-6">
                    <div class="h3_course-item mb-30">
                      <div class="h3_course-item-top">
                        <div class="h3_course-item-img">
                          <a href="course-details.html">
                            <img src={Topper3} alt="" />
                          </a>
                          <div class="h3_course-item-tag">
                            <a href="#">3rd</a>
                          </div>
                          <div class="h3_course-item-save">
                            <a href="#">
                              <i class="fa-light fa-bookmark"></i>
                            </a>
                          </div>
                        </div>
                        <div class="h3_course-content">
                          <div class="h3_course-content-info">
                            <ul>
                              <li style={{fontSize:'16px'}}>
                              <strong>Name:</strong> Jorge
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>Department:</strong> ECE
                              </li>
                              <li style={{fontSize:'16px'}}>
                              <strong>CGPA:</strong> 9.2
                              </li>
                            </ul>
                          </div>
                        
                        </div>
                      </div>
                   
                    </div>
                  </div>
                </div>
              </div>
           
            
            </div>
          </div>
        </div>
      </section>
      {/* admission */}
      <section class="h3_admission-area pt-140 pb-90">
        <img src={Admission} alt="" class="h3_admission-bg" />
        <img src={AdmissionShape1} alt="" class="h3_admission-shape-1" />
        <img src={AdmissionShape3} alt="" class="h3_admission-shape-2" />
        <div class="container">
          <div class="row">
            <div class="col-xl-6 col-lg-6 ">
              <div class="h3_admission-wrap mr-65 mb-50">
                <img
                  src={AdmissionShape2}
                  alt=""
                  class="h3_admission-wrap-shape-2"
                />
                <div class="section-area-3 mb-35 small-section-area-3">
                  <span class="section-subtitle">Suggestions</span>
                  <h2 class="section-title mb-25">
                  Student Suggestions
                  </h2>
                  <p class="section-text">
                  At our institution, we value your feedback and believe that your suggestions play a crucial role in enhancing the learning experience for all. We encourage you to share your thoughts on how we can improve our courses, facilities, or student services. Whether it’s new ideas for campus events, improvements in teaching methods, or suggestions for better facilities, your voice matters.
we can build a better learning environment that caters to the diverse needs of all students. We look forward to hearing your thoughts and working together to make our institution even better.
                  </p>
                </div>
              
              </div>
            </div>
            <div class="col-xl-6 col-lg-6">
              <div class="h3_admission-form mb-50">
                <h5 class="h3_admission-form-title">Suggestions</h5>
                <form action="#">
                  <div class="row g-15">
                    <div class="col-xl-6 col-sm-6">
                      <div class="h3_admission-form-input">
                        <input type="text" placeholder="First Name" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-sm-6">
                      <div class="h3_admission-form-input">
                        <input type="text" placeholder="Last Name" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-sm-6">
                      <div class="h3_admission-form-input">
                        <input type="email" placeholder="Department" />
                      </div>
                    </div>
                    <div class="col-xl-6 col-sm-6">
                      <div class="h3_admission-form-input">
                        <input type="text" placeholder="Year" />
                      </div>
                    </div>
                    
                    <div class="col-12">
                      <div class="h3_admission-form-input">
                        <input type="date" />
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="h3_admission-form-input">
                        <textarea
                          name="message"
                          placeholder="Message"
                        ></textarea>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="h3_admission-form-btn">
                        <button
                          type="submit"
                          class="theme-btn h3_admission-btn theme-btn-full theme-btn-3"
                        >
                          Send<i class="fa-light fa-arrow-right"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeContents;